import { sum } from 'lodash';
import { VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'Components/Core/Routes/Hooks/RouterHook';

import { DescargaConductorDTO, GraficaDescargaConductorDTO } from './I000000DTO';
import { DonutChartWidget } from './Widgets/DonutChartWidgetComponent';

interface IConductoresDescargaChartProps
{
    conductores: Array<DescargaConductorDTO>;
}

export const ConductoresDescargaChart: VoidFunctionComponent<IConductoresDescargaChartProps> = ({ conductores }) =>
{
    const { t } = useTranslation(['/FMT/I/I00/I000000/I000000'], { useSuspense: false });
    const navigate = useNavigate();

    const pendienteDescarga = conductores.filter(e => e.pendienteDescarga && e.diasPendientes > 28);
    const quedanCincoDias = conductores.filter(e => e.diasPendientes >= 23 && e.diasPendientes <= 28);
    const descargados = conductores.filter(e => !e.pendienteDescarga && e.diasPendientes < 23);

    const datosGrafica: Array<GraficaDescargaConductorDTO> = [
        {
            denominacionTipo: t('widgetConductores.pendienteDescarga'),
            numeroElementos: pendienteDescarga.length,
            color: '#C7001E',
        },
        {
            denominacionTipo: t('widgetConductores.descargados'),
            numeroElementos: descargados.length,
            color: '#53C700'
        },
        {
            denominacionTipo: t('widgetConductores.faltanCincoDias'),
            numeroElementos: quedanCincoDias.length,
            color: '#f8B85E'
        }
    ];

    const donutCenterRender = () => (
        <>
            <h5 className='totalConductores'>
                {sum(datosGrafica.map(e => e.numeroElementos))}
            </h5>
            <p>Total</p>
        </>
    );

    return (
        <DonutChartWidget<GraficaDescargaConductorDTO>
            title={t('widgetConductores.titulo')}
            className='conductores'
            data={datosGrafica}
            categoryField='denominacionTipo'
            field='numeroElementos'
            donutCenterRender={donutCenterRender}
            onClick={() => navigate('/C000000')}>
            <p className='contador'>
                {datosGrafica.find(e => e.denominacionTipo === t('widgetConductores.pendienteDescarga'))?.numeroElementos ?? 0}
            </p>
            <p className='titulo'> {t('widgetConductores.pendienteDescarga')} </p>
        </DonutChartWidget>
    );
};
ConductoresDescargaChart.displayName = 'ConductoresDescargaChart';
